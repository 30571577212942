<template>
    <div class="app-email-builder">
        <iframe
            :src="sa_email_builder_url"

            width="100%"
            :height="height"
            scrolling="no"

            @load="onBuilderLoaded"

            ref="builder"
        ></iframe>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        value: { required: true },
        loading: { type: Boolean, default: false },
    },

    data() {
        return {
            height: 950,
            messages: [],

            win_width: null,

            watching_height_id: 0,

            loaded: false,
        }
    },

    mounted() {
        window.addEventListener('resize', this.handleWindowResize)
        window.addEventListener('message', this.handlerMessage)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('message', this.handlerMessage)
    },

    methods: {
        handleWindowResize() {
            const clientWidth = document.body.clientWidth

            if (clientWidth != this.win_width) {
                this.win_width = clientWidth
                this.changeHeight()
            }
        },

        handlerMessage(event) {
            // console.group('MVNO handlerMessage(event)')
            // console.log('event', event)
            // console.log('event.origin', event.origin)
            // console.log('this.sa_email_builder_url', this.sa_email_builder_url)
            // console.log('event.origin == this.sa_email_builder_url', event.origin == this.sa_email_builder_url)

            if (event.origin == this.sa_email_builder_url) {
                if (event.data && event.data.action) {
                    switch (event.data.action) {
                        case 'change-email': {
                            this.self = true
                            this.$emit('input', event.data.mjml)
                            // console.warn('>>> CALL this.watchingChangeHeight')
                            this.watchingChangeHeight()
                        } break
                    }
                }
            }

            // console.groupEnd()
        },

        onBuilderLoaded() {
            // console.group('onBuilderLoaded()')
            // console.log('contentWindow', this.$refs.builder.contentWindow)
            // console.log('this.$refs.builder', this.$refs.builder)
            // console.log('scrollHeight', this.$refs.builder.contentWindow.document.body.scrollHeight)
            // console.log('clientHeight', this.$refs.builder.contentWindow.document.body.clientHeight)

            this.loaded = true

            this.postSavedMessages()
            
            // console.groupEnd()
        },
        
        changeHeight() {
            // console.group('changeHeight()')
            // console.log('this.$refs.builder.contentWindow.document.body.scrollHeight', this.$refs.builder.contentWindow.document.body.scrollHeight)

            const email_builder = this.$refs.builder.contentWindow.document.getElementById('email-builder')
            // console.log('email_builder', email_builder)
            // console.dir(email_builder)

            this.height = email_builder ? email_builder.scrollHeight : this.$refs.builder.contentWindow.document.body.scrollHeight

            // console.groupEnd()
        },
        watchingChangeHeight(iteration = 5, id) {
            // console.group('watchingChangeHeight(iteration = 5, id) {')
            // console.log('iteration', iteration)
            // console.log('id', id)

            if (iteration > 0) {
                if (!id) {
                    id = ++this.watching_height_id
                    // console.log('id = ++this.watching_height_id', id, this.watching_height_id)
                }

                // console.warn('id == this.watching_height_id', id == this.watching_height_id)

                if (id == this.watching_height_id) {
                    const height = this.height

                    setTimeout(() => {
                        // console.group(`watchingChangeHeight Timeout #${ id }`)
                        // console.log('id == this.watching_height_id', id == this.watching_height_id, id, this.watching_height_id)
                        if (id == this.watching_height_id) {
                            // console.log('height', height)
                            // console.log('this.height', this.height)
                        
                            // console.log('> RECALL this.watchingResize')
                            this.watchingChangeHeight( Math.floor(Math.abs(this.height - height)) ? 5 : iteration - 1, id )
                        }
                        // console.groupEnd()
                    }, 100)

                    this.changeHeight()
                }
            }

            // console.groupEnd()
        },

        postMessage(message) {
            if (this.loaded) {
                // console.warn('this.$refs.builder.contentWindow.postMessage ==>', this.sa_email_builder_url)
                this.$refs.builder.contentWindow.postMessage(message, this.sa_email_builder_url)
            } else {
                this.saveMessage(message)
            }
        },
        postSavedMessages() {
            while (this.messages.length) {
                this.postMessage( this.messages.shift() )
            }
        },
        saveMessage(message) {
            const index = this.messages.findIndex(item => item.action == message.action)

            if (index > -1) {
                this.messages.splice(index, 1)
            }

            switch (message.action) {
                case 'loading': {
                    this.messages.unshift(message)
                } break

                default: {
                    this.messages.push(message)
                }
            }
        },
    },

    watch: {
        value() {
            if (this.self) {
                this.self = false
            } else {
                this.postMessage({
                    action: 'set-email',
                    mjml: this.value,
                })
            }
        },

        loading() {
            if (this.loading) {
                this.postMessage({
                    action: 'loading'
                })
            }
        },
    },

    computed: {
        ...mapGetters([
            'sa_email_builder_url',
        ])
    },
}
</script>

<style lang="scss">
// .app-email-builder {}
</style>