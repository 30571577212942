<template>
    <div class="page-digital-comms-template-email">
        <div class="row">
            <div class="col-12">
                <div class="board">
                    <app-loader v-if="loading || processing"></app-loader>

                    <app-error v-model="error"></app-error>

                    <app-email-builder v-model="email" :loading="loading"></app-email-builder>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appEmailBuilder from '@/components/app-email-builder'

import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,

        appEmailBuilder,
    },

    data() {
        return {
            email: null,

            error: null,

            loaded: false,
            loading: false,
            processing: false,
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadEmail()
        },

        loadEmail() {
            this.loading = true
            this.$store.dispatch('api_notification/getCachedNotificationTemplateByUUID', { SPID: this.current_spid, UUID: this.uuid }).then((template) => {
                this.email = template.TemplateEmailMJML
                this.loading = false
            }).catch(error => {
                this.error = errMessage(error)

                this.loading = false
            })
        },
    },

    computed: {
        uuid() {
            return this.$route.params.uuid
        },
    },
}
</script>

<style lang="scss">
.page-digital-comms-template-email {
    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-bottom: 16px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-digital-comms-template-email {
        .board {
            padding: 16px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-digital-comms-template-email {
        .board {
            padding: 16px 8px 8px;
        }
    }
}
</style>